import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Device by Device ID`}</h1>
    <h2>{`GET `}<inlineCode parentName="h2">{`/v1/ems/device/{device_id}`}</inlineCode></h2>
    <p>{`Gets a device record from device ID for the user.`}</p>
    <h3>{`Request`}</h3>
    <h4>{`Headers`}</h4>
    <pre><code parentName="pre" {...{}}>{`"Authorization": "Bearer <Token>"
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Path Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`device_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The device ID of the device.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Response `}<inlineCode parentName="h3">{`200`}</inlineCode></h3>
    <p>{`The device has been successfully found and returned.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "id": [string] ID of record,
    "device_name": [string] Device name,
    "device_id": [string] Generated device ID,
    "os": [string] Device OS System
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`401`}</inlineCode></h3>
    <p>{`Authorization issue.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "Error": "Unauthorized",
    "message": [string] Authorization issue
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`You are not authorized to access this resource`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The token from Firebase is invalid`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Authorization header is missing`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`There is no "Authorization" in the header request.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Invalid authorization header format`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The "Authorization" header does not have "Bearer Token" format.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`404`}</inlineCode></h3>
    <p>{`A device was not found with that device ID for the user.`}</p>
    <h3>{`Response `}<inlineCode parentName="h3">{`500`}</inlineCode></h3>
    <p>{`Internal error with the server.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": "Internal Server Error",
    "message": "Internal Server Error"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      